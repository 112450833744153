import React, { useEffect, useState } from 'react'
import { Modal, Button, Empty } from 'antd'
import './../../assets/scss/typography.scss'
import './index.scss'
import SearchList from 'components/shared/search/SearchList'
import useWindowDimensions from 'hooks/window-dimensions'

const BasicPopupList = (props) => {
  const {
    isOpenPopupList,
    listData,
    getStations,
    bookingData,
    setBookingConfig,
    onClose,
    filter,
    onSelectedItemChanged=()=>{},
    dataVihcle,
    changeField,
    getBookingHours,
    handleCategory=()=>{},
    buttonText = 'Xác nhận',
    title = ' ',
    form,
    showSearch,
    onSearchCallback=()=>{},
    setFilter
  } = props
  const { width } = useWindowDimensions()
  const [searchText, setSearchText] = useState(undefined)
  const handleChangeValue=(item) => {
    onClose()
    if(item.data?.vehicleSubType === null){
      item.data.vehicleSubType =1
      item.data.vehicleSubCategory =1001
    }
    onSelectedItemChanged(prev=>({
      ...prev,
      [changeField]:item.data
    }))
    if(form){
      form.setFieldsValue({
        [changeField]:item.value
      })
    }
    if([changeField]=='vntId'){
      getStations({
        filter: {
          stationArea: item.value
        }
      })
      form.setFieldsValue({
        stationsId: null,
        dateSchedule: null,
        time: null
      })
      onSelectedItemChanged(prev=>({
        ...prev,
        stationsId: null,
        dateSchedule: null,
        time: null
      }))
    }
    if([changeField]=='stationsId'){
      setFilter({
        ...filter,
        stationsId: item.data.stationsId
      })
      const stationSelected = listData.find((e) => e.stationData.stationsId == item.stationData.stationsId)
      setBookingConfig(JSON.parse(stationSelected?.stationData?.stationBookingConfig))
      form.setFieldsValue({
        dateSchedule: null,
        time: null
      })
      onSelectedItemChanged(prev=>({
        ...prev,
        dateSchedule: null,
        time: null
      }))
    }
    if([changeField]=='dateSchedule'){
      let  stationsId = bookingData.stationsId.stationsId
      getBookingHours({
        stationsId: stationsId,
        date: item.data,
        vehicleType: dataVihcle.vehicleType
      })
      form.setFieldsValue({
        time: null
      })
      onSelectedItemChanged(prev=>({
        ...prev,
        time: null
      }))
    }
    if([changeField]=='vehicleIdentity'){
      setSearchText('')
      onSelectedItemChanged(item.data)
      setFilter({
        ...filter,
        vehicleSubType: item.data.vehicleSubType
      })
      handleCategory(item.data.vehicleSubType,item.data.vehicleSubCategory)
    }
    if([changeField]=='vntStation'){
      setSearchText('')
      onSelectedItemChanged(item)
    }
  }
  const onSearch = (value) => {
    if (value?.length >= 2) {
      onSearchCallback(value.toLowerCase().trim())
    }
    if (!value || value?.length <= 0) {
      onSearchCallback('')
    }
  }
  useEffect(() => {
    onSearch()
  }, [searchText])
  return (
    <div >
      <Modal title={title} destroyOnClose={true} width={width > 1280 ? "35%" : '100%'} visible={isOpenPopupList}  onCancel={onClose}
      footer ={<Button className='btn-ok' onClick={onClose}>{buttonText}</Button>}
      className="popup-booking"
      style={{
        top: (width > 1280 ? '10%': '0'),
        textAlign:'center',
      }}
      >
      {showSearch && 
        <SearchList placeholder="Tìm kiếm" value={searchText} onInput={()=>onSearch()} onClick={()=>{setSearchText(undefined)}} className="w-100" onSearch={onSearch} />
      }
      <div style={{display:'grid'}}>
        {listData.length > 0 ?
          listData.map(item=>{
          return (
            <button className={`list-items ${item.disable ? 'disabled-item' : ''} `} style={{border:'none'}} disabled={item.disable} onClick={()=>handleChangeValue(item)}>
              {(item.status ? item.status : item.value)}
            </button>
          )
        }) : <div className='p-2'><Empty description="Không tìm thấy kết quả phù hợp" /></div>
        }
      </div>
      </Modal>
    </div>
  )
}

export default BasicPopupList
